import { TipsSection } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, useRef } from 'react';
import Slider from 'react-slick';

import TipsPostSection from '../Section';
import * as styles from './tipsPostSlider.module.css';

interface Props extends StyleProps, TestIdProps {
  sections: TipsSection[];
  onSectionVisible?: (section: TipsSection) => void;
}

export const TipsPostSlider: FC<Props> = ({ testId, className, sections, style, onSectionVisible }) => {
  const ref = useRef<Slider>(null);

  const handleNavigateClick = (direction: 'prev' | 'next') => () => {
    const slider = ref.current;
    if (!slider) return;
    if (direction === 'prev') {
      slider.slickPrev();
    } else {
      slider.slickNext();
    }
  };

  return (
    <div data-testid={testId} className={mc(className)} style={style}>
      <Slider ref={ref} className={mc('w-full', styles.slider)} infinite={false} dots>
        {sections.map((section, index) => (
          <div key={section.title} className="w-full px-2">
            <TipsPostSection
              className="w-full"
              index={index + 1}
              isFirst={index === 0}
              isLast={index === sections.length - 1}
              section={section}
              onNextClick={handleNavigateClick('next')}
              onPrevClick={handleNavigateClick('prev')}
              onVisible={() => onSectionVisible && onSectionVisible(section)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export type TipsPostSliderProps = Props;
export default TipsPostSlider;
