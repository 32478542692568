import { TipsSection } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC } from 'react';

interface Props extends StyleProps, TestIdProps {
  sections: TipsSection[];
  visible?: TipsSection;
}

export const TipsPostTableOfContentsList: FC<Props> = ({ testId, className, style, sections, visible }) => {
  return (
    <div data-testid={testId} className={mc(className)} style={style}>
      <h3 className={mc('m-0 p-0 pl-8', 'text-xl font-raleway leading-normal font-bold text-ocean-blue')}>Table of Contents</h3>
      <ul>
        {sections.map((section, index) => (
          <li key={section.slug} className="mt-3 relative">
            <span
              className={mc(
                'absolute left-0 top-0 bottom-0',
                'w-1.5',
                'rounded-md',
                'bg-ocean-blue',
                section.slug !== visible?.slug && 'hidden',
              )}
            />
            <a
              className={mc(
                'block',
                'pl-8',
                'font-sharpsans leading-normal text-ocean-blue',
                'hover:underline',
                section.slug !== visible?.slug && 'font-medium',
                section.slug === visible?.slug && 'underline font-bold',
              )}
              href={`#${section.slug}`}
            >
              {`Tip ${index + 1}: ${section.title}`}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export type TipsPostTableOfContentsListProps = Props;
export default TipsPostTableOfContentsList;
