import { ContainedBtn } from '@components/Buttons';
import { GuidesAndTipsDocument } from '@core/content';
import { getGuiedsAndTipsDocumentPath } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { FC } from 'react';

interface Props extends StyleProps, TestIdProps {
  post: GuidesAndTipsDocument;
}

export const ReadMoreSectionSlide: FC<Props> = ({ testId, className, style, post }) => {
  const title = post.itemContent.content.headerTitle;
  const image = getImage(post.headerImage as ImageDataLike);
  return (
    <div
      data-testid={testId}
      className={mc('w-[300px] h-[520px]', 'flex flex-col items-stretch', 'rounded-xl overflow-hidden', className)}
      style={style}
    >
      {/* Image */}
      {image && (
        <div
          className={mc('w-full h-[300px]', 'rounded-t-xl', 'bg-no-repeat bg-cover bg-center')}
          style={{ backgroundImage: `url(${image.images.fallback?.src})` }}
        />
      )}
      {/* Image End */}
      {/* Info */}
      <div className={mc('px-8 py-8', 'flex flex-col', 'rounded-b-xl', 'bg-white')}>
        <div className="flex-1">
          <h5 className={mc('m-0 p-0', 'text-xl font-raleway leading-normal font-bold line-clamp-3')}>{title}</h5>
        </div>
        <div className="mt-2.5">
          <ContainedBtn size="sm" href={getGuiedsAndTipsDocumentPath(post)}>
            {'Read More'}
          </ContainedBtn>
        </div>
      </div>
      {/* Info End */}
    </div>
  );
};

export type ReadMoreSectionSlideProps = Props;
export default ReadMoreSectionSlide;
