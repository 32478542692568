import { GuidesAndTipsDocument } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, useRef } from 'react';

import ArrowLeftIcon from './assets/arrowLeft.inline.svg';
import ArrowRightIcon from './assets/arrowRight.inline.svg';
import ReadMoreSectionSlide from './components/Slide';

interface Props extends StyleProps, TestIdProps {
  posts: GuidesAndTipsDocument[];
}

export const TipsPostReadMoreSection: FC<Props> = ({ testId, className, style, posts }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleNavClick = (direction: 'prev' | 'next') => () => {
    const slider = scrollContainerRef.current;
    if (!slider) return;
    const scrollStep = 300;
    if (direction === 'prev' && slider.scrollLeft === 0) return;
    if (direction === 'next' && slider.scrollLeft === slider.scrollWidth - slider.offsetWidth) return;
    if (direction === 'prev') {
      slider.scrollTo({ left: slider.scrollLeft - scrollStep, behavior: 'smooth' });
    } else {
      slider.scrollTo({ left: slider.scrollLeft + scrollStep, behavior: 'smooth' });
    }
  };

  return (
    <section
      data-testid={testId}
      className={mc('px-8 lg:px-9 py-16 lg:py-20', 'w-full', 'bg-ocean-blue', className)}
      style={style}
    >
      <div className="font-raleway leading-normal tracking-wide font-bold text-white">Guides & Tips</div>
      <div className={mc('flex flex-row justify-between')}>
        <h3 className={mc('m-0 p-0', 'text-3xl lg:text-5xl font-literata leading-tight font-medium text-white')}>
          Related articles
        </h3>
        <div className="flex flex-row justify-center items-center">
          <button
            className={mc('w-8 h-8', 'flex items-center justify-center', 'hover:opacity-70')}
            role="button"
            onClick={handleNavClick('prev')}
          >
            <ArrowLeftIcon />
          </button>
          <button
            className={mc('w-8 h-8', 'flex items-center justify-center', 'hover:opacity-70')}
            role="button"
            onClick={handleNavClick('next')}
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
      {/* Scroll */}
      <div className={mc('w-full', 'mt-8', 'relative')}>
        <div className={mc('w-full', 'flex flex-row', 'overflow-y-hidden', 'box-border')} ref={scrollContainerRef}>
          {posts.map(post => (
            <div key={post.id}>
              <ReadMoreSectionSlide className="px-4" post={post} />
            </div>
          ))}
        </div>
        <div
          className={mc(
            'hidden md:block',
            'w-[100px] h-full',
            'absolute top-0 -right-1 bottom-0',
            'bg-gradient-to-r from-transparent to-ocean-blue',
          )}
        />
      </div>
      {/* Scroll End */}
    </section>
  );
};

export type TipsPostReadMoreSectionProps = Props;
export default TipsPostReadMoreSection;
