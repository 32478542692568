import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, MouseEvent } from 'react';

import EmailIcon from './assets/email.inline.svg';
import FacebookIcon from './assets/facebook.inline.svg';
import LinkedinIcon from './assets/linkedin.inline.svg';
import SmsIcon from './assets/sms.inline.svg';
import LinkIcon from './assets/link.inline.svg';
import TwitterIcon from './assets/twitter.inline.svg';
import { StaticImage } from 'gatsby-plugin-image';

interface Props extends StyleProps, TestIdProps {
  href?: string;
  icon: TipsPostHeaderSocialBtnIcon;
  label?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

type TipsPostHeaderSocialBtnIcon = 'facebook' | 'instagram' | 'twitter' | 'linkedin' | 'email' | 'sms' | 'link';

export const TipsPostHeaderSocialBtn: FC<Props> = ({ testId, className, style, href, label, icon, onClick }) => {
  return (
    <a
      data-testid={testId}
      className={mc(
        'w-12 h-12',
        'rounded-full',
        'bg-white shadow-sm',
        'flex justify-center items-center',
        'hover:opacity-70',
        className,
      )}
      style={{ lineHeight: 0, fontSize: 0, ...style }}
      href={href}
      target="__blank"
      title={label}
      onClick={onClick}
    >
      {icon === 'facebook' && <FacebookIcon />}
      {icon === 'email' && <EmailIcon />}
      {icon === 'linkedin' && <LinkedinIcon />}
      {icon === 'twitter' && <TwitterIcon />}
      {icon === 'sms' && <SmsIcon />}
      {icon === 'link' && <LinkIcon />}
      {icon === 'instagram' && <StaticImage alt={label || ''} src="./assets/instagram.png" />}
      {label}
    </a>
  );
};

export type TipsPostHeaderSocialBtnProps = Props;
export default TipsPostHeaderSocialBtn;
