import { PostCategoryTag } from '@components/Post';
import { TipsPost } from '@core/content';
import { Routes } from '@navigation';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { FC, MouseEvent } from 'react';
import { useIsClient } from 'usehooks-ts';

import SocialBtn from './components/SocialBtn';

interface Props extends StyleProps, TestIdProps {
  post: TipsPost;
}

export const TipsPostheader: FC<Props> = ({ testId, className, style, post }) => {
  const { title, subtitle, headerImage, description, eyebrowHeader, categories } = post;
  const image = getImage(headerImage as ImageDataLike);

  const isClient = useIsClient();

  const handleCopyLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigator.clipboard.writeText(location.href);
    setTimeout(() => {
      alert('Link copied to clipboard!');
    }, 300);
  };

  return (
    <>
      <section
        data-testid={testId}
        className={mc('relative', 'flex flex-col lg:flex-row items-stretch', 'bg-light-gray', className)}
        style={style}
      >
        {/* Descr Column */}
        <div className={mc('w-full lg:w-5/12', 'px-8 lg:px-20 pt-12 lg:pt-16 pb-12 lg:pb-44')}>
          <a
            className="mb-4 font-semibold inline-block text-dark hover:text-accent-orange"
            target="_blank"
            href={Routes.affiliateDisclosure.url}
          >
            Affiliate Disclosure
          </a>
          <div className="-mx-1 -my-1">
            {categories && categories.length ? (
              categories.map(category => <PostCategoryTag key={category} containerClassName="px-1 py-1" title={category} />)
            ) : (
              <PostCategoryTag containerClassName="px-1 py-1" title={'Caregiving Guide'} />
            )}
          </div>
          <h1 className={mc('mt-4 mb-0', 'text-4xl lg:text-6xl lg:leading-extra-snug tracking-little-wide')}>{title}</h1>
          {!!description && (
            <p className={mc('m-0 p-0 mt-4', 'text-lg font-raleway leading-normal font-medium text-ocean-blue')}>{description}</p>
          )}
          <div className={mc('mt-4', 'flex flex-row flex-start items-center flex-wrap')}>
            <SocialBtn
              className="mr-1.5 lg:mr-3"
              icon="facebook"
              label="Share to Facebook"
              href={isClient ? `https://www.facebook.com/sharer/sharer.php?u=${location.href}` : '#'}
            />
            {/** https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview */}
            {/** Use &text= to add text */}
            <SocialBtn
              className="mr-1.5 lg:mr-3"
              icon="twitter"
              label="Share to Twitter"
              href={isClient ? `https://twitter.com/intent/tweet?url=${location.href}` : '#'}
            />
            {/** https://learn.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/plugins/share-plugin */}
            <SocialBtn
              className="mr-1.5 lg:mr-3"
              icon="linkedin"
              label="Share to LinkedIn"
              href={isClient ? `https://www.linkedin.com/sharing/share-offsite/?url=${location.href}` : '#'}
            />
            <SocialBtn
              className="mr-1.5 lg:mr-3"
              icon="email"
              label="Share to Email"
              href={isClient ? `mailto:?body=${location.href}` : '#'}
            />
            <SocialBtn
              className="mr-1.5 lg:mr-3"
              icon="link"
              label="Share link"
              href={isClient ? `sms:?body=${location.href}` : '#'}
              onClick={handleCopyLinkClick}
            />
          </div>
        </div>
        {/* Descr Column End */}
        {/* Image Column */}
        <div className={mc('w-full lg:w-7/12 h-[467px] lg:h-auto', 'flex flex-col justify-center', 'relative')}>
          {!!image && (
            <div
              className={mc('bg-center bg-cover bg-no-repeat', 'absolute top-0 bottom-0 left-0 right-0')}
              style={{ backgroundImage: `url(${image.images?.fallback?.src})` }}
            />
          )}
        </div>
        {/* Image Column End */}
      </section>
      {/* What to Do Right Now */}
      <section className={mc('relative -top-40', 'w-9/12 lg:w-5/12', 'bg-white', 'px-7 lg:px-12 pt-14', 'rounded-tr-2xl')}>
        <div className={mc('font-raleway leading-normal tracking-wide font-bold text-accent-green')}>
          {eyebrowHeader || 'Falls & Fractures'}
        </div>
        <h2 className={mc('p-0 m-0 mt-2', 'text-3xl lg:text-5xl font-literata leading-tight font-medium text-ocean-blue')}>
          What to do right now
        </h2>
        {!!subtitle && (
          <p className={mc('m-0 p-0 mt-2', 'text-lg font-raleway leading-normal font-medium text-ocean-blue')}>{subtitle}</p>
        )}
      </section>
      {/* What to Do Right Now End */}
    </>
  );
};

export type TipsPostheaderProps = Props;
export default TipsPostheader;
