import { TipsSection } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import ArrowLeftIcon from './assets/arrowLeft.inline.svg';
import ArrowRightIcon from './assets/arrowRight.inline.svg';
import DotListIcon from './assets/dotListIcon.inline.svg';
import ProListIcon from './assets/proListIcon.inline.svg';
import SeparatorVerticalIcon from './assets/separatorVertical.inline.svg';
import StarIcon from './assets/star.inline.svg';
import SubtitleUnderlineIcon from './assets/subtitleUnderline.inline.svg';
import parseHtml from 'html-react-parser';

interface Props extends StyleProps, TestIdProps {
  index: number;
  isFirst?: boolean;
  isLast?: boolean;
  section: TipsSection;
  onVisible?: () => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}

export const TipsPostSection: FC<Props> = ({
  testId,
  className,
  style,
  section,
  index,
  isFirst,
  isLast,
  onVisible,
  onPrevClick,
  onNextClick,
}) => {
  const { title, slug, merchant, description, tips } = section;

  const [ref, inView, entry] = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (!entry || !inView) return;
    onVisible && onVisible();
  }, [inView]);

  return (
    <div
      data-testid={testId}
      className={mc(
        'p-4 lg:p-8',
        'flex flex-col-reverse items-stretch lg:flex-row',
        'rounded-2xl',
        'bg-pale-aqua lg:bg-seafoam',
        className,
      )}
      style={style}
      id={slug}
      ref={ref}
    >
      {/* Merchant */}
      {!!merchant && (
        <a
          className={mc(
            'block',
            'w-full lg:w-[317px]',
            'px-4 lg:px-8 py-4 mt-6 lg:mt-0',
            'flex flex-col items-center justify-center',
            'bg-white',
            'border border-neutral-gray rounded-2xl',
          )}
          href={merchant.callToActionUrl || '#'}
          target="_blank"
        >
          <StarIcon />
          <span className={mc('mt-2', 'block', 'text-md font-raleway leading-normal text-center font-medium text-ocean-blue')}>
            CircleOf Recommends
          </span>
          {!!merchant.imageUrl && (
            <img
              className={mc('mt-6', 'block', 'w-full max-w-[250px]')}
              src={merchant.imageUrl}
              alt={merchant.description || ''}
            />
          )}
          {!!merchant.description && (
            <span className={mc('mt-3', 'block', 'text-md font-raleway leading-normal text-center font-medium text-ocean-blue')}>
              {parseHtml(merchant.description)}
            </span>
          )}
          {!!merchant.callToActionText && (
            <span className={mc('mt-6', 'block', 'flex flex-row justify-center')}>
              <span
                className={mc(
                  'block',
                  'px-4 py-2',
                  'min-w-[72px]',
                  'rounded-full',
                  'bg-ocean-blue',
                  'text-white text-center text-sm font-raleway leading-normal font-semibold',
                )}
              >
                {merchant.callToActionText}
              </span>
            </span>
          )}
        </a>
      )}
      {/* Merchant End */}
      {/* Separator */}
      <div className={mc('hidden lg:flex', 'lg:px-6 pt-6 lg:pt-0', 'flex-row items-center')}>
        <SeparatorVerticalIcon />
      </div>
      {/* Separator End */}
      {/* Tip */}
      <div className={mc('w-full')}>
        <div
          className={mc(
            'flex flex-col items-center lg:items-start',
            'text-xl font-raleway leading-normal font-bold text-ocean-blue',
          )}
        >
          <div className="w-full flex flex-row justify-between items-center">
            <button
              className={mc('lg:hidden', 'w-6 h-6 sm:w-10 sm:h-10 rounded-full flex justify-center items-center')}
              role="button"
              onClick={onPrevClick}
            >
              <ArrowLeftIcon className={mc(isFirst && 'hidden')} />
            </button>
            <div>{`Tip ${index}:`}</div>
            <button
              className={mc('lg:hidden', 'w-6 h-6 sm:w-10 sm:h-10 rounded-full flex justify-center items-center')}
              role="button"
              onClick={onNextClick}
            >
              <ArrowRightIcon className={mc(isLast && 'hidden')} />
            </button>
          </div>
          <SubtitleUnderlineIcon className="block" />
        </div>
        {/* Title */}
        <div className={mc('mt-2', 'text-center lg:text-left')}>
          {/* Desktop */}
          <a
            className={mc('hidden lg:block', 'font-literata font-medium text-ocean-blue hover:opacity-70')}
            target="__blank"
            href={merchant?.callToActionUrl || `#${slug}`}
          >
            <span className={mc('inline-block', 'text-3xl')}>{title}</span>
          </a>
          {/* Desktop End */}
          {/* Mobile */}
          <span className={mc('block lg:hidden', 'font-literata font-medium text-ocean-blue')}>
            <span className={mc('inline-block', 'text-3xl')}>{title}</span>
          </span>
          {/* Mobile End */}
        </div>
        {/* Title End */}
        {/* Description */}
        {!!description && (
          <div className={mc('mt-4', 'text-lg font-raleway leading-normal font-medium text-ocean-blue')}>{description}</div>
        )}
        {/* Description End */}
        <ul className={mc('mt-4')}>
          {tips.map((tip, index) => (
            <li
              key={index}
              className={mc(
                'mt-3',
                'flex flex-row items-start',
                'text-lg font-raleway leading-normal font-medium text-ocean-blue',
              )}
            >
              {tip.toLowerCase().includes('get extra help') ? (
                <span className={mc('block', '-mt-1', 'w-9 h-9', 'flex flex-row justify-center items-center')}>
                  <ProListIcon height={36} />
                </span>
              ) : (
                <span className={mc('block', 'w-9 h-6', 'flex flex-row justify-center items-center')}>
                  <DotListIcon />
                </span>
              )}
              <span className={mc('block flex-1', 'ml-2.5')}>{parseHtml(tip)}</span>
            </li>
          ))}
        </ul>
      </div>
      {/* Tip End */}
    </div>
  );
};

export type TipsPostSectionProps = Props;
export default TipsPostSection;
