import { SeoHeader } from '@components/Common';
import { CookiePolicy } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import { TipsPost, TipsSection } from '@core/content';
import { mc } from '@styles';
import React, { FC, useState } from 'react';

import TipsPostheader from './components/Header';
import ReadMoreSection from './components/ReadMoreSection';
import TipsPostSection from './components/Section';
import TipsPostSlider from './components/Slider';
import TipsPostTableOfContentsList from './components/TableOfContentsList';
import { ImageDataLike, getImage } from 'gatsby-plugin-image';

interface PageContext {
  slug: string;
  post: TipsPost;
}

interface Props {
  pageContext: PageContext;
}

const TipsPostTemplate: FC<Props> = ({ pageContext }) => {
  const { post } = pageContext;
  const { sections, relatedPosts, disclosure } = post;

  const [curVisibleSection, setCurVisibleSection] = useState<TipsSection | undefined>(sections[0]);

  return (
    <>
      <NavigationHeader />
      <TipsPostheader post={post} />
      <section className={mc('-mt-28 lg:-mt-16 pb-16 lg:pb-32', 'overflow-x-hidden lg:overflow-x-auto', 'bg-white')}>
        <div className={mc('px-6', 'flex flex-col lg:flex-row')}>
          {/* Tips */}
          <main className={mc('lg:w-9/12', 'lg:px-6')}>
            <div className="flex flex-col  lg:flex-row lg:items-end lg:justify-between">
              <h3
                className={mc('p-0 m-0', 'text-3xl lg:text-5xl text-center lg:text-left font-literata leading-tight font-medium')}
              >
                {`Tips & Solutions`}
              </h3>
            </div>
            {/* Tips List */}
            <div className={mc('hidden lg:block')}>
              {sections.map((section, index) => (
                <TipsPostSection
                  key={section.title}
                  className="mt-5"
                  index={index + 1}
                  section={section}
                  onVisible={() => setCurVisibleSection(section)}
                />
              ))}
            </div>
            {/* Tips List End */}
            {/* Tips Carousel */}
            <TipsPostSlider className="lg:hidden mt-8" sections={sections} onSectionVisible={itm => setCurVisibleSection(itm)} />
            {/* Tips Carousel End */}
            {/* Disclosure */}
            {!!disclosure && (
              <p className={mc('p-0 m-0 mt-20 lg:mt-9', 'font-raleway leading-normal font-medium text-ocean-blue')}>
                {disclosure}
              </p>
            )}
            {/* Disclosure End */}
          </main>
          {/* Tips End */}
          {/* Desktop Table of Contest */}
          <aside className={mc('hidden lg:block', 'w-full lg:w-3/12', 'lg:px-6')}>
            <TipsPostTableOfContentsList className="lg:sticky lg:top-4" sections={sections} visible={curVisibleSection} />
          </aside>
          {/* Desktop Table of Contest End */}
        </div>
      </section>
      {/* Read More */}
      {!!relatedPosts && relatedPosts.length && <ReadMoreSection posts={relatedPosts} />}
      {/* Read More End */}
      <CookiePolicy />
      <Footer privacyBannerColor="seafoam" />
    </>
  );
};

export const Head: FC<Props> = ({ pageContext }) => {
  const { post } = pageContext;
  const { headerImage, title } = post;
  const image = getImage(headerImage as ImageDataLike);

  return (
    <SeoHeader
      canonical={undefined}
      url={undefined}
      type="article"
      image={image?.images?.fallback?.src}
      itemProp={undefined}
      description={undefined}
      keywords={undefined}
      title={title}
    />
  );
};

export default TipsPostTemplate;
